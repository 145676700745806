import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { Loader } from "@components/loader/loader.js";
import ResizeObserver from 'resize-observer-polyfill';

const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById('root'));

if (typeof window.ResizeObserver === 'undefined') {
  window.ResizeObserver = ResizeObserver;
}

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
