import React from "react";
import Spinner from "@assets/LoadingIcon.svg";

const Loader = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={Spinner} alt="Loading..." className="animate-spin fill-black" />
    </div>
  );
};

export { Loader };